import React, { useState } from "react"
import { navigate } from "gatsby"
import Icon from "@mdi/react"
import {
  mdiAccountOutline,
  mdiCellphoneIphone,
  mdiEmailOutline,
  mdiCalendar,
  mdiBus,
  mdiPillar,
  mdiDomain,
  mdiMagnify,
  mdiMessageOutline,
} from "@mdi/js"
import { Row, Col, H2 } from "../UI"
import { Collapse } from "react-collapse"
import NumberFormat from "react-number-format"
import { db } from "../../firebase/firebase"
import moment from "moment"

const WeddingForm = props => {
  const d = new Date()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [weddingDate, setWeddingDate] = useState("")
  const [service, setService] = useState("")
  const [venue, setVenue] = useState("")
  const [hotel, setHotel] = useState("")
  const [hearAbout, setHearAbout] = useState("")
  const [notes, setNotes] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [page] = useState(props.pageName)

  const handleSubmit = e => {
    e.preventDefault()
    if (firstName && lastName && email && phone && weddingDate && service) {
      db.collection("Form Submissions")
        .add({
          "First Name": firstName,
          "Last Name": lastName,
          Email: email,
          Phone: phone,
          "Wedding Date": weddingDate,
          Service: service,
          Venue: venue,
          Hotel: hotel,
          "Found Us": hearAbout,
          Notes: notes,
          "Date Submitted": moment().format("MMMM Do YYYY, h:mm:ss a"),
          Page: page,
          Time: d.getTime(),
        })
        .then(() => {
          setTimeout(() => {
            navigate("/thank-you", { state: { firstName } })
          }, 1000)
        })
    }
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div className="py-6 px-6 bg-subtle shadow-md">
      <H2>Request a Quote</H2>
      <p className="mb-6">
        Congratulations! If you’d like to schedule a call with our Wedding
        Coordinator, Click Here. If not, continue with the form below.
      </p>
      <form noValidate onSubmit={handleSubmit} name={props.formName}>
        <Row>
          <Col className="w-full sm:w-1/2 mb-6" gutter="sm">
            <label htmlFor="firstName" className="block">
              <Icon
                path={mdiAccountOutline}
                size={0.8}
                className="inline mb-1 mr-1"
              />
              First Name <span className="text-purp-100 text-lg">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              value={firstName}
              placeholder="First"
              onChange={e => setFirstName(e.target.value)}
              className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
              required
            />
          </Col>
          <Col className="w-full sm:w-1/2 mb-6" gutter="sm">
            <label htmlFor="lastName" className="block">
              <Icon
                path={mdiAccountOutline}
                size={0.8}
                className="inline mb-1 mr-1"
              />
              Last Name <span className="text-purp-100 text-lg">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              value={lastName}
              placeholder="Last"
              onChange={e => setLastName(e.target.value)}
              className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
              required
            />
          </Col>
          <Col className="w-full sm:w-1/2 mb-6" gutter="sm">
            <label htmlFor="phone" className="block">
              <Icon
                path={mdiCellphoneIphone}
                size={0.8}
                className="inline mb-1 mr-1"
              />
              Phone Number <span className="text-purp-100 text-lg">*</span>
            </label>
            <NumberFormat
              displayType="input"
              name="phone"
              value={phone}
              placeholder="555-555-5555"
              format="(###) ###-####"
              mask="_"
              onChange={e => setPhone(e.target.value)}
              className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
              required
            />
          </Col>
          <Col className="w-full sm:w-1/2 mb-6" gutter="sm">
            <label htmlFor="email" className="block">
              <Icon
                path={mdiEmailOutline}
                size={0.8}
                className="inline mb-1 mr-1"
              />
              Email <span className="text-purp-100 text-lg">*</span>
            </label>
            <input
              type="text"
              name="email"
              value={email}
              placeholder="Your Email"
              onChange={e => setEmail(e.target.value)}
              className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
              required
            />
          </Col>
          <Col className="w-full sm:w-1/2 mb-6" gutter="sm">
            <label htmlFor="weddingDate" className="block">
              <Icon
                path={mdiCalendar}
                size={0.8}
                className="inline mb-1 mr-1"
              />
              Wedding Date <span className="text-purp-100 text-lg">*</span>
            </label>
            <input
              type="text"
              name="weddingDate"
              value={weddingDate}
              placeholder="mm/dd/yyy"
              onChange={e => setWeddingDate(e.target.value)}
              className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
              required
            />
          </Col>
          <Col className="w-full sm:w-1/2 mb-6" gutter="sm">
            <label htmlFor="service" className="block">
              <Icon path={mdiBus} size={0.8} className="inline mb-1 mr-1" />
              Service Type <span className="text-purp-100 text-lg">*</span>
            </label>
            <select
              type="text"
              name="service"
              value={service}
              onChange={e => {
                setService(e.target.value)
                setIsOpen(true)
              }}
              className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full appearance-none"
              required
            >
              <option value="---">---</option>
              <option value="Wedding Party Transportation">
                Wedding Party Transportation
              </option>
              <option value="Guest Shuttle Service">
                Guest Shuttle Service
              </option>
              <option value="Both">Both</option>
              <option value="Other">Other</option>
            </select>
          </Col>
          <Col className="w-full sm:w-1/2 mb-6" gutter="sm">
            <label htmlFor="venue" className="block">
              <Icon path={mdiPillar} size={0.8} className="inline mb-1 mr-1" />
              Wedding Venue
            </label>
            <input
              type="text"
              name="venue"
              value={venue}
              placeholder="Your Venue"
              onChange={e => setVenue(e.target.value)}
              className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
            />
          </Col>
          <Col className="w-full sm:w-1/2 mb-6" gutter="sm">
            <label htmlFor="hotel" className="block">
              <Icon path={mdiDomain} size={0.8} className="inline mb-1 mr-1" />
              Hotel
            </label>
            <input
              type="text"
              name="hotel"
              value={hotel}
              placeholder="Your Hotel"
              onChange={e => setHotel(e.target.value)}
              className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
            />
          </Col>
          <Collapse
            isOpened={isOpen}
            theme={{
              collapse:
                "collapse transition-all duration-200 ease-in-out w-full",
              content: "content",
            }}
          >
            <div>
              <Col className="w-full mb-6" gutter="sm">
                <label htmlFor="hearAbout" className="block">
                  <Icon
                    path={mdiMagnify}
                    size={0.8}
                    className="inline mb-1 mr-1"
                  />
                  Where did you hear about us?
                </label>
                <input
                  type="text"
                  name="hearAbout"
                  value={hearAbout}
                  placeholder="Wedding Venue, Hotel, Google Search..."
                  onChange={e => setHearAbout(e.target.value)}
                  className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
                />
              </Col>
              <Col className="w-full mb-6" gutter="sm">
                <label htmlFor="notes" className="block">
                  <Icon
                    path={mdiMessageOutline}
                    size={0.8}
                    className="inline mb-1 mr-1"
                  />
                  Notes
                </label>
                <textarea
                  name="notes"
                  value={notes}
                  placeholder="Tell us a little more about your wedding transportation needs"
                  onChange={e => setNotes(e.target.value)}
                  className="border-gray-300 border rounded px-2 py-3 shadow-sm bg-white w-full"
                />
              </Col>
            </div>
          </Collapse>
          <input
            type="submit"
            value="Request a Quote"
            className="bg-purp-100 hover:bg-purp-200 px-6 py-3 text-white w-full cursor-pointer"
          />
        </Row>
      </form>
    </div>
  )
}

export default WeddingForm
