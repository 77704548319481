import React from "react"
import Layout from "../components/Layout"
import { H1, H3, H4, Container, Row, Col, ButtonPurp } from "../components/UI"
import SectionTitle from "../components/SectionTitle"
import PageHeader from "../components/PageHeader"
import { RegularCard } from "../components/Cards"
import VehicleCard from "../components/VehicleCard"
import ToggleBox from "../components/ToggleBox"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import Slide from "react-reveal/Slide"
import VisibilitySensor from "react-visibility-sensor"
import CountUp from "react-countup"
import ReviewCards from "../components/ReviewCards"
import WeddingForm from "../components/forms/WeddingForm"

export default () => {
  return (
    <Layout>
      <PageHeader
        pageTitle="Wedding Transportation"
        divider="/images/dividers/bubbles.svg"
      >
        <Container>
          <Row className="items-center pt-8 pb-20 sm:py-24">
            <Col className="w-full lg:w-3/5 mt-4 lg:mt-0 text-center lg:text-left">
              <H1>Luxury Wedding Transportation</H1>
              <p className="text-white text-sm sm:text-base">
                Kevin Smith Transportation Group is the only choice for luxury
                wedding transportation, providing everything from bridal party
                transportation to wedding guest shuttle services. With the
                area’s largest selection of party buses, limos, and shuttles,
                our Wedding Coordinator will ensure your wedding day is nothing
                short of spectacular.
              </p>
            </Col>
            <Col className="w-full lg:w-2/5 mt-4 lg:mt-0">
              <div className="bg-white w-full py-4 px-3 text-center">
                <H3>Schedule a Call</H3>
                <p className="text-sm md:text-base">
                  Most weddings require a sophisticated coordination plan. This
                  is what we do best. Call and ask to speak to one of our
                  Wedding Coordinators between 8am and 6pm Monday thru Friday.
                  Want to schedule a call? Send an email to our Wedding
                  Coordinator with the day and time that works best for you.
                </p>
                <ButtonPurp shadow className="mt-4">
                  Schedule a Call
                </ButtonPurp>
              </div>
            </Col>
          </Row>
        </Container>
      </PageHeader>
      <section className="py-20">
        <Container>
          <Zoom>
            <SectionTitle
              title="Why Choose Us?"
              text="More than 500 wedding venues can’t be wrong. Kevin Smith Transportation Group is the #1 rated luxury wedding transportation company for the following reasons."
            />
          </Zoom>
          <Row className="py-12 items-center">
            <Col className="w-full lg:w-1/2 mb-4 lg:mb-0">
              <Zoom>
                <img
                  src="/images/weddings/wedding-transportation-party-bus.jpg"
                  alt="wedding transportation party bus"
                  className="border-4 border-purp-100 border-solid"
                ></img>
              </Zoom>
            </Col>
            <Col className="w-full lg:w-1/2 mb-4 lg:mb-0">
              <Fade right>
                <H4>The Area's Only 5-Star Service</H4>
                <p className="mb-3 text-sm">
                  You become Philadelphia’s only choice for luxury wedding
                  transportation by working at it every day. At Kevin Smith
                  Transportation Group, this is what we do.
                </p>
              </Fade>
              <Fade right>
                <H4>Dedicated Wedding Coordinators</H4>
                <p className="mb-3 text-sm">
                  Wedding planning can be stressful enough. Let Kevin Smith
                  Transportation Group’s Wedding Coordinators build out your
                  wedding transportation so you don’t have to.
                </p>
              </Fade>
              <Fade right>
                <H4>Luxury is Spelled KSTG</H4>
                <p className="mb-3 text-sm">
                  There should be no surprises when your limo, party bus or
                  shuttle arrive. Booking with KSTG means being confident that
                  everything will be perfect on your wedding day.
                </p>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container
          fluid
          style={{
            backgroundImage: "linear-gradient(150deg,#0b7bda 0%,#ff00c8 100%)",
          }}
        >
          <div
            className="h-24 bottom-0 block w-full bg-cover bg-top"
            style={{
              backgroundImage:
                "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgODYiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIHNsaWNlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDBIMHY2NS4yYzYuOCAwIDEzLjUuOSAyMC4xIDIuNiAxNC0yMS44IDQzLjEtMjggNjQuOC0xNCA1LjYgMy42IDEwLjMgOC4zIDE0IDEzLjkgNy4zLTEuMiAxNC44LS42IDIxLjggMS42IDIuMS0zNy4zIDM0LjEtNjUuOCA3MS40LTYzLjcgMjQuMyAxLjQgNDYgMTUuNyA1Ni44IDM3LjYgMTktMTcuNiA0OC42LTE2LjUgNjYuMyAyLjRDMzIzIDU0IDMyNy40IDY1IDMyNy43IDc2LjVjLjQuMi44LjQgMS4yLjcgMy4zIDEuOSA2LjMgNC4yIDguOSA2LjkgMTUuOS0yMy44IDQ2LjEtMzMuNCA3Mi44LTIzLjMgMTEuNi0zMS45IDQ2LjktNDguMyA3OC44LTM2LjYgOS4xIDMuMyAxNy4yIDguNyAyMy44IDE1LjcgNi43LTYuNiAxNi43LTguNCAyNS40LTQuOCAyOS4zLTM3LjQgODMuMy00NCAxMjAuNy0xNC44IDE0IDExIDI0LjMgMjYuMSAyOS40IDQzLjEgNC43LjYgOS4zIDEuOCAxMy42IDMuOCA3LjgtMjQuNyAzNC4yLTM4LjMgNTguOS0zMC41IDE0LjQgNC42IDI1LjYgMTUuNyAzMC4zIDMwIDE0LjIgMS4yIDI3LjcgNi45IDM4LjUgMTYuMiAxMS4xLTM1LjcgNDktNTUuNyA4NC43LTQ0LjcgMTQuMSA0LjQgMjYuNCAxMy4zIDM1IDI1LjMgMTItNS43IDI2LjEtNS41IDM3LjkuNiAzLjktMTEuNiAxNS41LTE4LjkgMjcuNy0xNy41LjItLjMuMy0uNi41LS45IDIzLjMtNDEuNCA3NS44LTU2IDExNy4yLTMyLjYgMTQuMSA3LjkgMjUuNiAxOS43IDMzLjMgMzMuOCAyOC44LTIzLjggNzEuNS0xOS44IDk1LjMgOSAyLjYgMy4xIDQuOSA2LjUgNi45IDEwIDMuOC0uNSA3LjYtLjggMTEuNC0uOEwxMjgwIDB6Ii8+PC9nPjwvc3ZnPg==)",
            }}
          ></div>
          <Row className="py-20">
            <Container className="w-full mx-auto max-w-screen-lg">
              <Row>
                <Col className="w-full sm:w-1/2 lg:w-1/4 text-center text-white mb-8 lg:mb-0">
                  <CountUp end={453} separator="," duration={2}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <p ref={countUpRef} className="text-6xl" />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <hr className="text-white w-16 mx-auto"></hr>
                  <p className="font-heading text-2xl">Reviews</p>
                </Col>
                <Col className="w-full sm:w-1/2 lg:w-1/4 text-center text-white mb-8 lg:mb-0">
                  <CountUp end={99} separator="," suffix="%" duration={2}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <p ref={countUpRef} className="text-6xl" />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <hr className="text-white w-16 mx-auto"></hr>
                  <p className="font-heading text-2xl">On-Time Rating</p>
                </Col>
                <Col className="w-full sm:w-1/2 lg:w-1/4 text-center text-white mb-8 lg:mb-0">
                  <CountUp end={9346} separator="," duration={2}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <p ref={countUpRef} className="text-6xl" />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <hr className="text-white w-16 mx-auto"></hr>
                  <p className="font-heading text-2xl">Clients</p>
                </Col>
                <Col className="w-full sm:w-1/2 lg:w-1/4 text-center text-white mb-8 lg:mb-0">
                  <CountUp end={36487} separator="," duration={2}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <p ref={countUpRef} className="text-6xl" />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <hr className="text-white w-16 mx-auto"></hr>
                  <p className="font-heading text-2xl">Trips Completed</p>
                </Col>
              </Row>
            </Container>
          </Row>
          <div
            className="h-24 bottom-0 block w-full bg-cover bg-top"
            style={{
              backgroundImage:
                "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxNDVweCIgdmlld0JveD0iMCAwIDEyODAgODYiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIHNsaWNlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDBIMHY2NS4yYzYuOCAwIDEzLjUuOSAyMC4xIDIuNiAxNC0yMS44IDQzLjEtMjggNjQuOC0xNCA1LjYgMy42IDEwLjMgOC4zIDE0IDEzLjkgNy4zLTEuMiAxNC44LS42IDIxLjggMS42IDIuMS0zNy4zIDM0LjEtNjUuOCA3MS40LTYzLjcgMjQuMyAxLjQgNDYgMTUuNyA1Ni44IDM3LjYgMTktMTcuNiA0OC42LTE2LjUgNjYuMyAyLjRDMzIzIDU0IDMyNy40IDY1IDMyNy43IDc2LjVjLjQuMi44LjQgMS4yLjcgMy4zIDEuOSA2LjMgNC4yIDguOSA2LjkgMTUuOS0yMy44IDQ2LjEtMzMuNCA3Mi44LTIzLjMgMTEuNi0zMS45IDQ2LjktNDguMyA3OC44LTM2LjYgOS4xIDMuMyAxNy4yIDguNyAyMy44IDE1LjcgNi43LTYuNiAxNi43LTguNCAyNS40LTQuOCAyOS4zLTM3LjQgODMuMy00NCAxMjAuNy0xNC44IDE0IDExIDI0LjMgMjYuMSAyOS40IDQzLjEgNC43LjYgOS4zIDEuOCAxMy42IDMuOCA3LjgtMjQuNyAzNC4yLTM4LjMgNTguOS0zMC41IDE0LjQgNC42IDI1LjYgMTUuNyAzMC4zIDMwIDE0LjIgMS4yIDI3LjcgNi45IDM4LjUgMTYuMiAxMS4xLTM1LjcgNDktNTUuNyA4NC43LTQ0LjcgMTQuMSA0LjQgMjYuNCAxMy4zIDM1IDI1LjMgMTItNS43IDI2LjEtNS41IDM3LjkuNiAzLjktMTEuNiAxNS41LTE4LjkgMjcuNy0xNy41LjItLjMuMy0uNi41LS45IDIzLjMtNDEuNCA3NS44LTU2IDExNy4yLTMyLjYgMTQuMSA3LjkgMjUuNiAxOS43IDMzLjMgMzMuOCAyOC44LTIzLjggNzEuNS0xOS44IDk1LjMgOSAyLjYgMy4xIDQuOSA2LjUgNi45IDEwIDMuOC0uNSA3LjYtLjggMTEuNC0uOEwxMjgwIDB6Ii8+PC9nPjwvc3ZnPg==)",
              transform: "scaleX(-1) rotateX(180deg)",
            }}
          ></div>
        </Container>
      </section>
      <section className="py-20">
        <Container>
          <Zoom>
            <SectionTitle
              title="Wedding Services"
              text="Whether you are looking for transportation for you wedding party, your guests or you and your new partner, we have just what you are looking for"
            />
          </Zoom>
          <Row className="pt-12">
            <Col className="w-full sm:w-1/2 lg:w-1/3 mb-8 lg:mb-0">
              <Slide bottom>
                <RegularCard
                  title="The Wedding Party"
                  text="When you are running from the ceremony, to pictures, to the hotel, to the venue and back to who knows where else, a KSTG limo, party bus or shuttle is the perfect solution for you."
                  image="/images/weddings/wedding-party-bus.jpg"
                />
              </Slide>
            </Col>
            <Col className="w-full sm:w-1/2 lg:w-1/3 mb-8 lg:mb-0">
              <Slide bottom>
                <RegularCard
                  title="The Wedding Guests"
                  text="Hotels aren’t for out of town guests anymore. As your room block grows, getting your friends and relatives back and forth to the venue safely and on-time works perfectly in a party bus or shuttle."
                  image="/images/weddings/wedding-guest-shuttle-service.jpg"
                />
              </Slide>
            </Col>
            <Col className="w-full sm:w-1/2 lg:w-1/3 mb-8 lg:mb-0">
              <Slide bottom>
                <RegularCard
                  title="The Bride & Groom"
                  text="After hundreds of toasts and too much wedding cake, the privacy of a KSTG limo or black car to shuttle you home (or back to your hotel) is the perfect end to the perfect wedding day. You deserve it."
                  image="/images/weddings/wedding-bride-and-groom.jpg"
                />
              </Slide>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-20 bg-subtle">
        <Container>
          <Zoom>
            <SectionTitle
              title="Vehicle Options"
              text="We have a variety of vehicles to choose from, from shuttle service for your guests to limo and party buses for your wedding party"
            />
          </Zoom>
          <Row className="pt-12">
            <Col className="w-full sm:w-1/2 lg:w-1/3 mb-8 lg:mb-0">
              <Slide bottom>
                <VehicleCard name="18 Passenger Party Bus" button />
              </Slide>
            </Col>
            <Col className="w-full sm:w-1/2 lg:w-1/3 mb-8 lg:mb-0">
              <Slide bottom>
                <VehicleCard name="23 Passenger Party Bus" button />
              </Slide>
            </Col>
            <Col className="w-full sm:w-1/2 lg:w-1/3 mb-8 lg:mb-0">
              <Slide bottom>
                <VehicleCard name="Lincoln MKT Stretch" button />
              </Slide>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-20">
        <Container>
          <Zoom>
            <SectionTitle
              title="FAQ's"
              text="Do you have questions before you book? We’ll try to answer as many as we can for you."
            />
          </Zoom>
          <Row className="pt-12">
            <Col className="w-full mb-6 lg:mb-6">
              <Fade left>
                <ToggleBox
                  question="How does your guests shuttle service work?"
                  answer="The way we designed shuttle services for our wedding clients is by breaking up our 4-hour minimum booking time into two, 2-hour blocks – two hours before and two hours after the event. We will make as many trips in each 2-hour block between the hotel and venue to accommodate all of your guests."
                />
              </Fade>
            </Col>
            <Col className="w-full mb-6 lg:mb-6">
              <Fade right>
                <ToggleBox
                  question="Are you able to help me build out an itinerary?"
                  answer="Of course! Our wedding coordinators have lots of experience with most hotels, venues, and churches in the Philadelphia area to help you plan out and schedule the perfect itinerary for your special day."
                />
              </Fade>
            </Col>
            <Col className="w-full mb-6 lg:mb-6">
              <Fade left>
                <ToggleBox
                  question="What are your payment terms?"
                  answer="To secure your wedding transportation, we require a 50% deposit the day of booking. Your final payment will then be due 30 days before your wedding date."
                />
              </Fade>
            </Col>
            <Col className="w-full mb-6 lg:mb-6">
              <Fade right>
                <ToggleBox
                  question="Are there any other 'hidden fees' that I will have to pay?"
                  answer="Nope! Every price that you receive from us will include all fees as well as driver gratuity built in."
                />
              </Fade>
            </Col>
            <Col className="w-full mb-6 lg:mb-6">
              <Fade left>
                <ToggleBox
                  question="Do you offer any discounts?"
                  answer="We do! We offer discounts when booking multiple vehicles such as a party bus for the wedding party and a shuttle for your guests. We also partner with many hotels and venues in the area to bring you discounts when booking there."
                />
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-20 bg-subtle">
        <Container>
          <Zoom>
            <SectionTitle
              title="How Do We Compare"
              text="See how we compare to other Philadelphia based limo rental companies as well as national limo companies"
            />
          </Zoom>
          <Zoom>
            <Row className="pt-12 items-center">
              <ReviewCards />
            </Row>
          </Zoom>
        </Container>
      </section>
      <section className="py-20">
        <Container>
          <Zoom>
            <Row className="pt-12 justify-center">
              <Col className="w-full md:w-3/4 lg:w-2/3">
                <WeddingForm
                  formName="Wedding Form"
                  pageName="Wedding Transportation"
                />
              </Col>
            </Row>
          </Zoom>
        </Container>
      </section>
    </Layout>
  )
}
